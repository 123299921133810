import React, {useState, useEffect} from 'react';
import "./style.styl";

export default function WebDevelopmentPage(props) {
    return (
        <div className="container draft-view">
            <h1>Web Development Service&nbsp;</h1>
            <p className="text-muted">Web development is a necessary step to grow your business. We live in a time when the Internet and modern technologies have penetrated into every home and pocket. If you want to stand out in a competitive niche, get your business to the next level, reach a larger audience, or increase your contacts, you cannot afford to be without a web presence.</p>
            <p className="text-muted"><span style={{fontFamily: 'Poppins, Helvetica, sans-serif'}} className="text-muted">Wescale provides the full web development service that includes custom web development.</span><br /></p>
            <p className="text-muted">We provide website development services that are on the cutting edge of today’s technologies. Our Web developers are versed in all Web technologies such as WordPress, Drupal, Magento, Shopify. We even custom develop websites and web applications in Python Django, NodeJS, Ruby on Rails, and .NET. Our technical wizards mix a potent combination of nerd learning, geek fest participation, Spock-like technical brainstorm sessions, and endless hours watching online tech conferences and listening to propeller head podcasts. Are these guys geeks? You bet they are! Since 1998, Wescale has enjoyed coding over 200+ Websites from e-commerce, b2c, b2b, non-profit, to social networks. And yes, we even used to code in Perl back in the beginning days of the Internet.</p>
            <p className="text-muted"><br /></p>
            <h2>What is&nbsp;<font>Web Development</font> for business?</h2>
            <p className="text-muted"></p>
            <h5>Web development provides more benefits to do business.</h5>
            <p className="text-muted" />
            <div ><span style={{fontFamily: 'Poppins, Helvetica, sans-serif'}} className="text-muted">1.Web development is the process of creating a website or web application. The main stages of this process are web design (UI design, UX design, graphic design), front-end development, back-end development, and testing.</span></div>
            <p className="text-muted" />
            <div ><span style={{fontFamily: 'Poppins, Helvetica, sans-serif'}} className="text-muted">2.Our web designers will develop a logical site structure, format the presentation of information, and structure it all in an easy to maneuver and aesthetically pleasing site or application. In the end, our developers will present a finished layout that we will continue to refine.</span></div>
            <p className="text-muted" />
            <div ><span style={{fontFamily: 'Poppins, Helvetica, sans-serif'}} className="text-muted">3.Our front-end developers take this layout and transfer it to code to ensure that your website or application displays equally beautiful in all browsers, is convenient to use, and works correctly on all devices.</span></div>
            <p className="text-muted" />
            <div ><span style={{fontFamily: 'Poppins, Helvetica, sans-serif'}} className="text-muted">4.The following is the work of our back-end developers, which consists of setting up the server part of the site or application that is not visible to visitors. This includes authorization, storage, and processing of data, email distribution, and the like. The task of the tester is to carry out quality control at all stages of development.</span></div>
            <div ><span style={{fontFamily: 'Poppins, Helvetica, sans-serif'}} className="text-muted"><br /></span></div>
            <p className="text-muted" />
            <h2>Wescale <font>Web Development</font> Process</h2>
            <p className="text-muted">With our web development company, there are many steps that can be divided into the following main categories:</p>
            <p className="text-muted" />
            <h5><span style={{fontSize: '1.25rem', fontFamily: 'Poppins, Helvetica, sans-serif'}}>1.Collection and analysis of information</span></h5>
            <p className="text-muted" />
            The proper approach for the development and enhancement of a web project relies on preliminary research, analysis, and collection of essential information, such as the target audience, the purpose of the product, and competitors. With this data, we form a foundation that determines future steps and stages of development.
            <p className="text-muted" />
            <h5><span style={{fontSize: '1.25rem', fontFamily: 'Poppins, Helvetica, sans-serif'}}>2.Technical specification and prototyping</span></h5>
            <p className="text-muted">Based on the information collected, technical documentation for the project and schematic wireframes are created for each page by our project manager.&nbsp;<span style={{fontFamily: 'Poppins, Helvetica, sans-serif'}} className="text-muted">A technical document describes all the requirements of the web project: its function, technology on which the project will be built, main milestones, timeframes, and technology stack (programming language, frameworks, CMS) to be used. Wireframes provide a schematic visual representation of each page of the future web project.</span></p>
            <p className="text-muted" />
            <h5><span style={{fontSize: '1.25rem', fontFamily: 'Poppins, Helvetica, sans-serif'}}>3.Web design (UI UX design)</span></h5>
            <p className="text-muted">The main purpose of the template is to visualize the structure and functionality of your future website or web app. At this stage, all the information collected in the first stage of analysis is implemented in visual design. Our designers develop layouts for every page of the site. When the layouts are finished, we will send them to you for review. Our goal is to update the layouts until you are 100% happy with your website.</p>
            <p className="text-muted">You can find more detailed and useful information about web design services.</p>
            <p className="text-muted" />
            <h5>
            <div><span style={{fontSize: '1.25rem', fontFamily: 'Poppins, Helvetica, sans-serif'}}>4.Front-end and Back-end web development</span></div>
            </h5>
            <p className="text-muted">Front-end developers turn the layouts of all the pages that were created in the previous step into real, dynamic, interactive, and responsive web pages. Our back-end developers support the functionality of each element. To help your site get found on internet search engines, on-page SEO, and tech SEO (Search Engine Optimization services) are considered at this stage. We fine-tune your web pages' meta tags, micro-markup, headers, robots, sitemap files, images, and keywords in order to maximize your site’s position in search results.</p>
            <p className="text-muted" />
            <h5><span style={{fontSize: '1.25rem', fontFamily: 'Poppins, Helvetica, sans-serif'}}>5.Testing, bugfix, and launch</span></h5>
            <p className="text-muted">Our QA engineers are involved in every stage of the project creation, so at this stage, only a final test needs to be performed. This strategy significantly speeds up the launch of your project. Every page, form, button, and the link is tested and verified. Our QA engineers test the site’s display in different browsers, on different devices, and at different screen resolutions in order to be sure that the generated code fully complies with modern web standards and rules. After the project has passed the final check, and all the bugs have been fixed, it can be uploaded. After launching the project, we will perform another testing cycle to ensure that no unexpected errors occurred during the download and that all files are safe and sound.</p>
            <p className="text-muted" />
            <h5>
            <div><span style={{fontSize: '1.25rem', fontFamily: 'Poppins, Helvetica, sans-serif'}}>6.Support and improvement</span></div>
            </h5>
            <p  className="text-muted">In order for the finished project to fulfill your business needs and bring you income, our relationship with you after launch is just as important for us. We maintain contact to be sure that everything works as planned, and you are satisfied with the final product. We provide three months of free support for all our projects after launch. We are ready and available to respond to any problems that may arise to remedy them quickly.</p>
            <p  className="text-muted"><br /></p>
            <h2 style={{fontFamily: 'Poppins, Helvetica, sans-serif'}}>Our Approach to Web Development</h2>
            <p style={{fontFamily: 'Poppins, Helvetica, sans-serif'}} className="text-muted">If you’re looking for custom Internet applications or complex web development solutions, you’ve come to the right place. Our in-house and super-seasoned web development professionals are hard-core trained in today’s bleeding-edge web technologies. Website development projects must be planned out perfectly to ensure success. Our technical project managers and developers work closely every day and always keep our clients’ interests #1. Our development team also works very closely with the UX and UI team to ensure best practices in user experience are always top of mind.</p>
            <p style={{fontFamily: 'Poppins, Helvetica, sans-serif'}} className="text-muted"><br /></p>
            <h2 >Where to order web development services?</h2>
            <p className="text-muted">The coherence of the team, the experience of each individual developer, an integrated approach, and preliminary planning of every task allows us to ensure consistent, high-quality, and profitable results.</p>
            <p className="text-muted">Wescale is a web development company and your reliable partner who has the ability to develop almost any website and web app, for any business, of any level of complexity. Web development can be carried out either from scratch or through various software solutions. In addition, we provide outsource web development and digital marketing services that make us a full-stack web agency.</p>
            <p className="text-muted">Just contact us, and we will orient you with our prices, as well as provide you with any further information that interests you.</p>

        </div>
    );
}
